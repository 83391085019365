<template>
  <v-container fluid class="py-0">
    <h2>Сурагчид дутуу Monitoring</h2>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="red"
    ></v-progress-linear>
    <v-data-table
      v-else-if="students"
      :headers="headerNames"
      :items="students"
      :items-per-page="-1"
    >
      <template v-slot:item.index="{ item }">
        <span @click="print(item)">{{ item.index }}</span>
      </template>
    </v-data-table>
    <div v-else>Empty</div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import moment from "moment";
export default {
  data: () => ({
    students: null,
    loading: false,
    headerNames: [
      {
        text: "ID",
        align: "start",
        width: "1%",
        value: "index",
      },
      {
        text: "Name",
        value: "TEACHER_NAME",
      },
      {
        text: "Бүлэг",
        value: "STUDENT_GROUP_NAME",
      },
      {
        text: "school",
        value: "schoolName",
      },
      {
        text: "failed students",
        value: "_failedStudentsNumberOutOf",
      },
      { text: "failed text", value: "_failedStudentsText" },
      {
        text: "createdAt",
        value: "_failedStudentsNotifiedAtTIME",
      },
    ],
  }),
  props: {},
  components: {},
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    fb.db
      .collection("_failingStudents")
      .orderBy("_failedStudentsNotifiedAt", "desc")
      .onSnapshot((docs) => {
        this.students = [];
        this.loading = true;
        var index = 1;
        docs.forEach((doc) => {
          let student = doc.data();
          student.ref = doc.ref;
          student.id = doc.id;
          student.index = index++;
          student["_failedStudentsNumberOutOf"] =
            student["_failedStudentsNumber"] +
            "/" +
            student["_failedStudentsNumberTobe"];
          student["_failedStudentsNotifiedAtTIME"] = new Date(
            student["_failedStudentsNotifiedAt"].toDate() - 86400000
          );
          this.students.push(student);
        });
        this.loading = false;
      });
  },

  methods: {
    print(item) {
      console.log(item);
    },
  },
  filters: {
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>
